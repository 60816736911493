<template>
  <div class="wrapper">
    <div class="card">
      <div class="text-center">
        <i class="fa-solid fa-circle-check mt-5" style="font-size: 48px; color: #3AB3E2"></i>
        <div class="mt-4" style="font-weight: 500; font-size: 18px">{{ $t('bind_success') }}</div>
        <div class="mt-4 mb-3" style="color: #686E74">{{ $t('success_note', { seconds: seconds }) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'update.success',
  data () {
    return {
      url: '',
      token: '',
      tid: '',
      seconds: 3
    }
  },
  created () {
    if (this.$route.params.mode === undefined) {
      this.$router.push({
        name: 'setting.index'
      })
      return false
    }
    this.url = this.$route.params.url
    this.token = this.$route.params.token
    this.tid = this.$route.params.tid
    setTimeout(() => {
      this.timer()
    }, 1000)
  },
  mounted () {
    window.onbeforeunload = function (e) {
      e = e || window.event
      e.returnValue = '確定離開此頁嗎?'
    }
    const vm = this
    window.addEventListener('unload', function (e) {
      vm.$store.dispatch('removeJWT')
      vm.$store.dispatch('removeMode')
      return true
    })
  },
  destroyed () {
    window.onbeforeunload = null
  },
  methods: {
    timer () {
      this.seconds--
      setTimeout(() => {
        if (this.seconds !== 1) {
          this.timer()
        } else {
          this.$router.push({
            name: 'setting.index'
          })
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 850px) {
  .card {
    margin: 0 16px 0 16px;
    box-shadow: 0px 15px 30px -10px rgba(92, 101, 107, 0.15);
  }
}
</style>
